.Toastify__toast.notification {
  width: 296px;
  border-radius: 16px;
  backdrop-filter: blur(16px);
}
.Toastify__toast-container--top-right {
  top: 16px;
  right: 16px;
}
.Toastify__toast-body {
  padding: 0;
}
.notification .Toastify__toast-icon {
  width: 32px;
  height: 32px;
}

.notification.success {
  background-color: #ddf5e3;
  border: 1px solid rgba(43, 197, 80, 0.16);
}

.notification.alert {
  background-color: #f9e3e2;
  border: 1px solid rgba(219, 82, 78, 0.16);
}

.notification.warning {
  background-color: #fff3dd;
  border: 1px solid rgba(255, 180, 44, 0.16);
}

.notification.neutral {
  background-color: #f4f7fa;
  border: 1px solid #e6ecf4;
}
