.ProseMirror {
  font-size: 0.9375rem;
  line-height: 1.5rem;
}

.ProseMirror.ProseMirror-focused .is-editor-empty::before {
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror ul + p,
.ProseMirror ol + p,
.ProseMirror p + p,
.ProseMirror ul + ul,
.ProseMirror ol + ul,
.ProseMirror p + ul,
.ProseMirror ul + ol,
.ProseMirror ol + ol,
.ProseMirror p + ol {
  margin-block-start: 0.75rem;
  margin-block-end: 0;
}

.ProseMirror ul {
  list-style: disc;
  padding-inline-start: 1.25rem;
}

.ProseMirror ol {
  list-style: decimal;
  padding-inline-start: 1.25rem;
}

.ProseMirror ul ul,
.ProseMirror ol ul,
.ProseMirror ul ol,
.ProseMirror ol ol {
  margin-block-start: 0;
}

.ProseMirror .marker {
  border-radius: 0.25rem;
  padding: 0.2rem 0 0.2rem 0;
}

.ProseMirror .stop-word-marker {
  color: #ff4a22;
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-skip-ink: none;
  position: relative;
  cursor: pointer;
}

.ProseMirror .stop-word-marker:hover {
  color: #d6594b;
}

.ProseMirror .stop-word-marker:hover:before {
  content: attr(data-title);
  position: absolute;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: white;
  background-color: #242424;
  border-radius: 12px;
  padding: 8px 12px;
  top: -5px;
  left: -10px;
  transform: translate(0, -100%);
  width: auto;
  width: 185px;
}

.ProseMirror .stop-word-marker:hover:after {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%229%22%20height%3D%226%22%20viewBox%3D%220%200%209%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M2.9736%205.07495C3.67861%206.27815%205.32139%206.27815%206.0264%205.07495L9%200H0L2.9736%205.07495Z%22%20fill%3D%22%23242424%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  position: absolute;
  top: -6px;
  left: 0px;
  width: 9px;
  height: 6px;
}
