.tooltip-container.tooltip-container {
  --tooltipBackground: #242424;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  max-width: 250px;
  white-space: break-spaces;
  padding: 8px 12px;
}
