@import "immigram-ui-cmon/src/components/RichEditor/RichEditor.css";
@import "immigram-ui-cmon/src/components/ContextHelp/ContextHelp.css";
@import "immigram-ui-cmon/src/components/Notification/Notification.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "ALSGranate";
    src: url("/fonts/ALS_Granate_VF_0.9.woff2") format("woff2");
    font-display: swap;
    font-style: normal;
    font-weight: 100 900;
  }
  @font-face {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 100 1000;
    font-stretch: 0% 200%;
    src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXpRJ6cXW4O8TNGoXjC79QRyaLshNDUf9-EmFw.woff2")
      format("woff2");
  }

  a {
    @apply text-stone-700 transition hover:text-stone-800 focus:outline-sky-500;
  }
  .richtext a {
    @apply text-sky-600 underline hover:text-sky-700 hover:no-underline;
  }
  button {
    @apply transition focus:outline-sky-500;
  }
  strong {
    @apply font-semibold;
  }
  ul,
  ol {
    list-style: inside;
  }
}

.overflow-x-without-scrollbar {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-x-without-scrollbar::-webkit-scrollbar {
  display: none;
}

/* text-stone-300 */
select:required:invalid {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}
option[value=""][disabled] {
  display: none;
}
/* text-stone-700 */
option {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-clip: text;
  transition: background-color 5000s ease-in-out 0s !important;
}
